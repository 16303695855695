@use './common' as func;
@use './fonts' as font;
@use './attributes' as attribute;
@use './breakpoints' as breakpoint;
@import './varibles';

@mixin TextInput{   
  border: var(--tm-input-border );
  background: var(--tm-input-background);
  box-shadow: var(--tm-input-shadow);

  color: var(--tm-input-color, rgba(var(--text-color-dark),1));
  border-radius: var(--tm-input-border-radius, 3px);
  padding: var(--tm-input-nolabel-padding, 12px);

  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus, -webkit-autofill:focus{
    border: var(--tm-input-active-border );
    outline: var(--tm-input-active-outline, none);
    background: var(--tm-input-active-background, var(--tm-input-background));
    box-shadow: var(--tm-input-active-shadow);
  }
  &:disabled, &:read-only {
    color: var(--tm-input-disabled-color, #333);
    background: var(--tm-input-disabled-background, #e3e2e2);
  }
  font-family: var(--tm-input-font-family, var(--font-family-helvetica));
  font-weight: var(--tm-input-font-weight, 300);
  font-size: var(--tm-input-font-size, 16px);

  @include attribute.placeholder(var(--tm-input-label-color, --text-color-gray));

  &.ng-dirty.ng-invalid {
    border: var(--tm-error-border);

    &:focus, -webkit-autofill:focus{
      outline: var(--tm-error-border, none);
    }
  }
}

@mixin Select {
  border: var(--tm-input-border );
  background: var(--tm-input-background);
  box-shadow: var(--tm-input-shadow);

  color: var(--tm-input-color);
  border-radius: var(--tm-input-border-radius, 3px);
  padding: var(--tm-input-nolabel-padding, 12px);

  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus, -webkit-autofill:focus{
    border: var(--tm-input-active-border );
    outline: var(--tm-input-active-outline, none);
    background: var(--tm-input-active-background, var(--tm-input-background));
    background-image: url('./../icons/down_arrow.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;

    box-shadow: var(--tm-input-active-shadow);
  }
  &:disabled, &[aria-readonly] {
    color: var(--tm-input-disabled-color, #333);
    background: var(--tm-input-disabled-background, #e3e2e2);
    background-image: url('./../icons/down_arrow.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
  }
  font-family: var(--tm-input-font-family, var(--font-family-helvetica));
  font-weight: var(--tm-input-font-weight, 300);
  font-size: var(--tm-input-font-size, 16px);

  @include attribute.placeholder(var(--tm-input-label-color, --text-color-gray));

  background-image: url('./../icons/down_arrow.svg');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;

  &.ng-dirty.ng-invalid {
    border: var(--tm-error-border);
    &:focus, -webkit-autofill:focus{
      outline: var(--tm-error-border, none);
    }
  }
}


@mixin button {
  width: 100%;

  font-family: var(--tm-button-font-family, var(--font-family-helvetica));
  font-size: var(--tm-button-font-size, 16px);
  font-weight: var(--tm-button-font-weight, 300);
  border-radius: var(--tm-button-border-radius, 3px);
  margin: var(--tm-button-margin, 0);
  padding: var(--tm-button-padding, 15px 10px);

  cursor: pointer;

  &:focus{
    outline-width: 0;
  }
}

@mixin radio {
  user-select: none;
  input{
    opacity: 0;
    position: absolute;  
  }

  input, label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    &[disabled]{
      cursor: default;
    }
  }

  >label {
    display: flex;
    place-items: center;

    position: relative;
    font-family: var(--tm-radio-font-family, var(--font-family-helvetica));
    font-size: var(--tm-radio-font-size);
    color: var(--tm-radio-label-color, var(--tm-radio-color, rgba(var(--text-color-dark, inherit), 0.5)));
    user-select: none;

    display: flex;
    background: var(--tm-radio-label-background, transparent);
    padding: var(--tm-radio-label-padding, 0);
    border: var(--tm-radio-label-border, none);
    border-radius: var(--tm-radio-label-border-radius, 4px);
    text-align: var(--tm-radio-label-align, left);    
    div{
      display: inline-block;
      span{
        display: block;
        &.bottom{
          font-size: 0.75em;
        }
      }
    }
  }

  input[type=radio] + label:before,
  input[type=checkbox] + label:before  {
    display: var(--tm-radio-icon-display, inline-block);
    width: var( --tm-radio-size, 27px);
    height: var( --tm-radio-size, 27px);
    content: var(--icon-content-empty);

    background: var(--tm-radio-icon-background);
    border: var(--tm-radio-border, none);
    vertical-align: middle;
    margin: var(--tm-radio-icon-margin, 0px 10px 0 0) ;
    text-align: center;

    color: var(--tm-radio-icon-color, var(--tm-radio-color));
    
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: var( --tm-radio-size, 27px);
    line-height: var( --tm-radio-size, 27px);
  }

  input[type=checkbox] + label:before{
    border-radius: var(--tm-checkbox-border-radius, 2px);
    content: var(--tm-checkbox-icon-content, var(--icon-content-check-empty));
    color: var(--tm-radio-icon-color, var(--tm-radio-color));
  }
  input[type=checkbox]:checked + label:before{
    content: var(--tm-checkbox-checked-icon-content, var(--icon-content-check-squared));
    color: var(--tm-radio-checked-icon-color, var(--tm-radio-color));
    background: var(--tm-radio-checked-icon-background, transparent);
    animation: 400ms linear checkbox-pop;
  }

  input[type=radio]:checked + label,
  input[type=checkbox]:checked + label {
    background: var(--tm-radio-checked-label-background);
    border: var(--tm-radio-checked-label-border, none);
    color: var(--tm-radio-checked-label-color, var(--tm-radio-color));
  }

  input[type=radio] + label:before{
    border-radius: var(--tm-radio-border-radius, func.map-deep-get($input, desktop, radio, radius));
    content: var(--tm-radio-icon-content, var(--icon-content-circle-empty-thin));
  }

  input[type=radio]:checked + label:before{
    content: var(--tm-radio-checked-icon-content, var(--icon-content-check-circled));
    color: var(--tm-radio-checked-icon-color, var(--tm-radio-color));
    background: var(--tm-radio-checked-background, transparent);
    animation: 400ms linear checkbox-pop;
  }

  input[type=radio]:disabled + label,
  input[type=checkbox]:disabled + label {
    color: var(--tm-radio-disabled-label-color, #919191);

    &:before {
      color: var(--tm-radio-disabled-icon-color, #919191);
    }
  }

  @keyframes checkbox-pop{
    0%{ transform: scale(1);}
    33%{ transform: scale(0.9);}
    66%{ transform: scale(1.1);}
    100%{ transform: scale(1);}
  }

}


@mixin radio-2 {
  user-select: none;
  input{
    opacity: 0;
    position: absolute;  
  }

  input, label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    &[disabled]{
      cursor: default;
    }
  }

  >label {
    display: flex;
    place-items: center;

    position: relative;
    font-family: var(--tm-radio-2-font-family, var(--font-family-helvetica));
    font-size: var(--tm-radio-2-font-size);
    color: var(--tm-radio-2-label-color, var(--tm-radio-2-color, rgba(var(--text-color-dark, inherit), 0.5)));
    user-select: none;

    display: flex;

    background: var(--tm-radio-2-label-background, transparent);
    padding: var(--tm-radio-2-label-padding, 0);
    border: var(--tm-radio-2-label-border, none);
    border-radius: var(--tm-radio-2-label-border-radius, 4px);
    text-align: var(--tm-radio-2-label-align, left);    
    div{
      display: inline-block;
      span{
        display: block;
        &.bottom{
          font-size: 0.75em;
        }
      }
    }
  }

  input[type=radio] + label:before,
  input[type=checkbox] + label:before  {
    display: var(--tm-radio-2-icon-display, inline-block);
    width: var( --tm-radio-2-size, 27px);
    height: var( --tm-radio-2-size, 27px);
    content: var(--icon-content-empty);

    background: rgba(var(--background-color),1);
    border: var(--tm-radio-2-border, none);
    vertical-align: middle;
    margin: var(--tm-radio-2-icon-margin, 0px 10px 0 0) ;
    text-align: center;

    color: var(--tm-radio-2-color, var(--tm-highlight-color));
    
    background-color: var(--tm-radio-2-background);
    
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: var( --tm-radio-2-size, 27px);
    line-height: var( --tm-radio-2-size, 27px);
  }

  input[type=checkbox] + label:before{
    border-radius: var(--tm-radio-2-border-radius, 2px);
    content: var(--tm-radio-2-icon-content, var(--icon-content-check-empty));
    color: var(--tm-radio-2-icon-color, var(--tm-radio-2-color, var(--tm-radio-color)));
  }
  input[type=checkbox]:checked + label:before{
    content: var(--tm-radio-2-checked-icon-content, var(--icon-content-check-squared));
    color: var(--tm-radio-2-checked-icon-color, var(--tm-highlight-color));
    background: var(--tm-radio-2-checked-background, transparent);
    animation: 400ms linear checkbox-pop;
  }

  input[type=radio]:checked + label,
  input[type=checkbox]:checked + label {
    background: var(--tm-radio-2-checked-label-background);
    border: var(--tm-radio-2-checked-label-border, none);
    color: var(--tm-radio-2-checked-label-color, var(--tm-radio-2-color));
  }

  input[type=radio] + label:before{
    border-radius: var(--tm-radio-2-border-radius, func.map-deep-get($input, desktop, radio, radius));
    content: var(--tm-radio-2-icon-content, var(--icon-content-circle-empty-thin));
  }

  input[type=radio]:checked + label:before{
    content: var(--tm-radio-2-checked-icon-content, var(--icon-content-check-circled));
    color: var(--tm-radio-2-checked-icon-color, var(--tm-highlight-color));
    background: var(--tm-radio-2-checked-background, transparent);
    animation: 400ms linear checkbox-pop;
  }

  input[type=radio]:disabled + label,
  input[type=checkbox]:disabled + label {
    color: var(--tm-radio-2-disabled-label-color, #919191);

    &:before {
      color: var(--tm-radio-2-disabled-icon-color, #919191);
    }
  }

  @keyframes checkbox-pop{
    0%{ transform: scale(1);}
    33%{ transform: scale(0.9);}
    66%{ transform: scale(1.1);}
    100%{ transform: scale(1);}
  }
}

@mixin checkbox {
  user-select: none;
  input{
    opacity: 0;
    position: absolute;  
  }

  input, label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    &[disabled]{
      cursor: default;
    }
  }

  >label {
    display: flex;
    line-height: 1.75em;

    position: relative;
    font-family: var(--tm-checkbox-font-family, var(--font-family-helvetica));
    font-size: var(--tm-checkbox-font-size);
    color: var(--tm-checkbox-label-color, var(--tm-checkbox-color));
    user-select: none;

    background: var(--tm-checkbox-label-background, transparent);
    padding: var(--tm-checkbox-label-padding, 0);
    border: var(--tm-checkbox-label-border, none);
    border-radius: var(--tm-checkbox-label-border-radius, 4px);
    text-align: var(--tm-checkbox-label-align, left);    
    div{
      display: inline-block;
      span{
        display: block;
        &.bottom{
          font-size: 0.75em;
        }
      }
    }
  }

  input[type=radio] + label:before,
  input[type=checkbox] + label:before  {
    display: var(--tm-checkbox-icon-display, inline-block);
    width: var( --tm-checkbox-size, 27px);
    height: var( --tm-checkbox-size, 27px);
    content: var(--icon-content-empty);

    background: rgba(var(--background-color),1);
    border: var(--tm-checkbox-border, none);
    vertical-align: middle;
    margin: var(--tm-checkbox-icon-margin, 0px 10px 0 0) ;
    text-align: center;

    color: var(--tm-checkbox-color);
    
    background-color: var(--tm-checkbox-background);
    
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: var( --tm-checkbox-size, 27px);
    line-height: var( --tm-checkbox-size, 27px);
    //animation: 400ms linear checkbox-pop;
  }

  input[type=checkbox] + label:before{
    border-radius: var(--tm-checkbox-border-radius, 2px);
    content: var(--tm-checkbox-icon-content, var(--icon-content-check-empty));
    color: var(--tm-checkbox-icon-color, var(--tm-checkbox-color, var(--tm-radio-color)));
  }
  input[type=checkbox]:checked + label:before{
    content: var(--tm-checkbox-checked-icon-content, var(--icon-content-check-squared));
    color: var(--tm-checkbox-checked-icon-color, var(--tm-highlight-color));
    background: var(--tm-checkbox-checked-background, transparent);
    animation: 400ms linear checkbox-pop;
  }

  input[type=radio]:checked + label,
  input[type=checkbox]:checked + label {
    background: var(--tm-checkbox-checked-label-background);
    border: var(--tm-checkbox-checked-label-border, none);
    color: var(--tm-checkbox-checked-label-color);
  }

  input[type=radio] + label:before{
    border-radius: var(--tm-checkbox-border-radius, func.map-deep-get($input, desktop, radio, radius));
    content: var(--tm-checkbox-icon-content, var(--icon-content-circle-empty-thin));
  }

  input[type=radio]:checked + label:before{
    content: var(--tm-checkbox-checked-icon-content, var(--icon-content-check-circled));
    color: var(--tm-checkbox-checked-icon-color, var(--tm-checkbox-color, var(--tm-radio-color)));
    background: var(--tm-checkbox-checked-background, transparent);
    animation: 400ms linear checkbox-pop;
  }

  input[type=radio]:disabled + label,
  input[type=checkbox]:disabled + label {
    color: var(--tm-checkbox-disabled-label-color, #919191);

    &:before {
      color: var(--tm-checkbox-disabled-icon-color, #919191);
    }
  }

  @keyframes checkbox-pop{
    0%{ transform: scale(1);}
    33%{ transform: scale(0.9);}
    66%{ transform: scale(1.1);}
    100%{ transform: scale(1);}
  }
}