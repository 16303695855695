
@use './assets/scss/common' as func;
@use './assets/scss/fonts' as font;
@use './assets/scss/input' as input;
@use './assets/scss/attributes' as attribute;
@import './assets/scss/varibles';
@import './assets/scss/reset';

@import "bootstrap/scss/bootstrap-grid";

//Old variables left in for backward-compatibility with existing AP CSS Config.
:root {
  --background-color: 255,255,255;
  --background-color-light: 245,248,254;
  --background-color-dark: 0,0,0;
  --text-color-light: 255,255,255;
  --text-color-gray: 158,167,175;
  --text-color-dark: 0,0,0;
  --primary-color: 46,217,195;
  --warning-color: 224,32,32;
  --highlight-color: 10,94,201;
  --font-family-helvetica: Helvetica;
  --font-family-helvetica: Helvetica;
  --font-family-helvetica-light: Helvetica;
  --logo-size: 100px;
  --background: rgba(var(--background-color),1);
}

//Variables for fontello font content 
:root {
  --icon-content-check-circled-thin: '\E801';
  --icon-content-check-circled: '\F06D';
  --icon-content-check: '\E803';
  --icon-content-check-empty: '\e80d';
  --icon-content-check-squared: '\e80e';
  --icon-content-check-squared-thin: '\e80c';
  --icon-content-dot-circled: '\F192';
  --icon-content-circle: '\F111';
  --icon-content-circle-empty: '\F10C';
  --icon-content-circle-empty-thin: '\F1DB';
  --icon-content-empty: '\E805';
  --icon-content-doughnut: '\E806';
}

:root {
  --border-radius-md: 4px;
  --border-radius-lg: 8px;
  --font-family: var(--font-family-helvetica);

  --tm-margin-sm: 5px;
  --tm-margin-md: 10px;
  --tm-margin-lg: 15px;

  --tm-margin--sm: -5px;
  --tm-margin--md: -10px;
  --tm-margin--lg: -15px;

  --padding-sm: 5px;
  --padding-lg: 10px;
  --padding-xl: 15px;
  
  --font-size-sm: 12px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 20px;
  --font-size-xxl: 24px;

  --tm-input-padding: 16px 12px 8px 12px;
  --tm-input-nolabel-padding: 8px 12px 8px 12px;
  --tm-input-label-padding: 12px;
  --tm-radio-label-border: none;
  --tm-radio-checked-label-border: none;
  --tm-error-text-color: #e02020;
  --tm-error-border: 1px solid #e02020;

  // THE MAIN PRIMARY COLOR OF THE APP
  --tm-primary-color: rgba(var(--primary-color));
  --tm-primary-fore-color: rgba(var(--text-color-light));
  --tm-primary-border: 1px solid #CDD8DB;

  --tm-secondary-color: rgba(var(--text-color-dark));
  --tm-secondary-fore-color: rgba(var(--text-color-light));
  --tm-secondary-border: 1px solid #CDD8DB;



  --tm-text-color: #333;
  --tm-text-color-05: #33333380;


  --tm-inactive-color: #919191;
  --tm-inactive-fore-color: #555;
  
  // DERIVED COLORS
  --tm-highlight-color: var(--tm-primary-color);
  --tm-primary-disabled-color: rgba(var(--primary-color), 0.5);
  --tm-icon-color-done: var(--tm-primary-color);
  --tm-icon-color-not-done: var(--tm-inactive-color);

  --tm-radio-color: #333;
  --tm-checkbox-color: #333;

  --tm-highlight-border: 2px solid var(--tm-highlight-color);

  --tm-alert-padding: 5px 5px 5px 16px;
  --tm-alert-color: #fff;
  --tm-alert-color-font-weight: regular;
  --tm-alert-background: #e02020bf;
  --tm-alert-margin: 15px 0 15px 0;
  --tm-alert-border: none;
  --tm-alert-border-radius: 4px;

  --tm-description-color: inherit;

  //div.step panel properties
  --tm-panel-font-family: var(--font-family);
  --tm-panel-font-size: var(--font-size-md);
  --tm-panel-background: #f5f8fe;
  --tm-panel-shadow: none;
  --tm-panel-color: #063c4a;
  --tm-panel-border: 1px solid #CDD8DB;
  --tm-panel-border-radius: var(--border-radius-lg);
  --tm-panel-padding: 12px 40px;
  --tm-panel-margin: 17px auto;

  --tm-panel-h2-font-family: inherit;
  --tm-panel-h2-font-size: 24px;
  --tm-panel-h2-font-weight: bold;

  --tm-panel-h3-font-family:inherit;
  --tm-panel-h3-font-size: 17px;
  --tm-panel-h3-font-weight: normal;

  --tm-button-primary-background: var(--tm-primary-color);
  --tm-button-primary-color: var(--tm-primary-fore-color);
  --tm-button-border-radius: 3px;
  --tm-button-disabled-background: var(--tm-primary-disabled-color);
  --tm-button-disabled-color: var(--tm-primary-fore-color);

  --tm-button-font-family: var(--font-family-helvetica);
  --tm-button-font-size: 16px;
  --tm-button-font-weight: 300;
  --tm-button-margin: 0;
  --tm-button-padding: 15px 10px;

  --tm-button-secondary-background: var(--tm-secondary-color);
  --tm-button-secondary-color: var(--tm-secondary-fore-color);
  
  --tm-checkbox-background: inherit;
  --tm-checkbox-border: none;
  --tm-checkbox-border-radius: 2px;
  --tm-checkbox-checked-background: transparent;
  --tm-checkbox-checked-icon-color: var(--tm-highlight-color);
  --tm-checkbox-checked-label-background: inherit;
  --tm-checkbox-checked-label-border: none;
  --tm-checkbox-checked-label-color: inherit;
  --tm-checkbox-color: inherit;
  --tm-checkbox-disabled-icon-color: #919191;
  --tm-checkbox-disabled-label-color: #919191;
  --tm-checkbox-font-family: var(--font-family);
  --tm-checkbox-font-size: inherit;
  --tm-checkbox-icon-color: var(--tm-checkbox-color, var(--tm-radio-color));
  --tm-checkbox-icon-content: var(--icon-content-check-empty);
  --tm-checkbox-icon-display: inline-block;
  --tm-checkbox-icon-margin: 0px 10px 0 0;
  --tm-checkbox-label-align: left;
  --tm-checkbox-label-background: transparent;
  --tm-checkbox-label-border: none;
  --tm-checkbox-label-border-radius: 4px;
  --tm-checkbox-label-color: var(--tm-checkbox-color);
  --tm-checkbox-label-padding: 0;
  --tm-checkbox-size: 27px;

  --tm-color-selector-border: 2px solid transparent;
  --tm-color-selector-selected-border: 2px solid var(--tm-highlight-color);
  --tm-color-selector-font-family: var(--font-family);
  --tm-color-selector-sample-width: 30px;
  --tm-color-selector-sample-height: 30px;
  --tm-color-selector-sample-border: none;
  --tm-color-selector-sample-border-radius: 50%;
  --tm-color-selector-sample-label-color: var(--tm-input-label-color);
  --tm-color-selector-sample-shadow: 1px 1px 3px 0px black;
  --tm-color-selector-sample-margin: 0 var(--tm-margin-md) 0 0;
  --tm-color-selector-padding: var(--tm-margin-sm);
  --tm-color-selector-border-radius: 40px;
  --tm-toggle-list-border-radius: 40px;

  //--tm-highlight-color: ;

  --tm-hint-font-size: 12px;
  --tm-hint-color: #333;

  --tm-input-background: ;
  --tm-input-active-background: var(--tm-input-background);
  --tm-input-active-border: var(--tm-primary-border);
  --tm-input-active-outline: none;
  --tm-input-active-shadow: inherit;
  --tm-input-border: var(--tm-primary-border);
  --tm-input-border-radius: 3px;
  --tm-input-color: rgb(var(--text-color-dark));
  --tm-input-disabled-background: #FFF;
  --tm-input-disabled-color: #BBB;
  --tm-input-font-family: var(--font-family);
  --tm-input-font-size: 16px;
  --tm-input-font-weight: 300;
  --tm-input-label-color: rgb(var(--text-color-gray));
  --tm-input-label-required-color: var(--tm-error-text-color);
  --tm-input-label-font-family: var(--font-family);
  --tm-input-label-font-size: 14px;
  --tm-input-nolabel-padding: 12px;
  --tm-input-shadow: none;
  
  //--tm-primary-color: ;
  --tm-radio-2-background: inherit;
  --tm-radio-2-border: none;
  --tm-radio-2-border-radius: 2px;
  --tm-radio-2-checked-background: transparent;
  --tm-radio-2-checked-icon-color: var(--tm-highlight-color);
  --tm-radio-2-checked-icon-content: var(--icon-content-check-circled);
  --tm-radio-2-checked-label-background: inherit;
  --tm-radio-2-checked-label-border: none;
  --tm-radio-2-checked-label-color: var(--tm-radio-2-color);
  --tm-radio-2-color: inherit;
  --tm-radio-2-disabled-icon-color: #919191;
  --tm-radio-2-disabled-label-color: #919191;
  --tm-radio-2-font-family: var(--font-family);
  --tm-radio-2-font-size: inherit;
  --tm-radio-2-icon-color: var(--tm-radio-2-color, var(--tm-radio-color));
  --tm-radio-2-icon-content: var(--icon-content-circle-thin);
  --tm-radio-2-icon-display: inline-block;
  --tm-radio-2-icon-margin: 0px 10px 0 0;
  --tm-radio-2-label-align: left;
  --tm-radio-2-label-background: transparent;
  --tm-radio-2-label-border: none;
  --tm-radio-2-label-border-radius: 4px;
  --tm-radio-2-label-color: var(--tm-radio-2-color);
  --tm-radio-2-label-padding: 0;
  --tm-radio-2-size: 27px;

  --tm-radio-background: transparent;
  --tm-radio-border: none;
  --tm-radio-border-radius: 2px;
  --tm-radio-checked-background: transparent;
  --tm-radio-checked-icon-background: transparent;
  --tm-radio-checked-icon-color: var(--tm-radio-color);
  --tm-radio-checked-icon-content: var(--icon-content-check-circled);
  --tm-radio-checked-label-background: ;
  --tm-radio-checked-label-border: none;
  --tm-radio-checked-label-color: var(--tm-radio-color);
  --tm-radio-color: inherit;
  --tm-radio-disabled-icon-color: #919191;
  --tm-radio-disabled-label-color: #919191;
  --tm-radio-font-family: var(--font-family-helvetica);
  --tm-radio-font-size: inherit;
  --tm-radio-icon-background: inherit;
  --tm-radio-icon-color: var(--tm-radio-color);
  --tm-radio-icon-content: var(--icon-content-circle-empty-thin);
  --tm-radio-icon-display: inline-block;
  --tm-radio-icon-margin: 0px 10px 0 0;
  --tm-radio-label-align: left;
  --tm-radio-label-background: transparent;
  --tm-radio-label-border: none;
  --tm-radio-label-border-radius: 4px;
  --tm-radio-label-color: var(--tm-radio-color);
  --tm-radio-label-padding: 0;
  --tm-radio-size: 27px;

  --tm-toggle-list-color: inherit;
  --tm-toggle-list-background: inherit;
  --tm-toggle-list-padding: 8px 16px;
  --tm-toggle-list-border: var(--tm-primary-border);
  --tm-toggle-list-border-radius: var(--border-radius-md);

  --tm-toggle-active-background: var(--tm-primary-color);
  --tm-toggle-active-border: inherit;
  --tm-toggle-active-color: rgba(var(--text-color-light), 1);
  --tm-toggle-background: var(--tm-inactive-background);
  --tm-toggle-border: #CCC 1px solid;
  --tm-toggle-border-radius: 100px;
  --tm-toggle-color: var(--tm-inactive-fore-color);
  --tm-toggle-font-family: inherit;
  --tm-toggle-height: 3rem;
  --tm-toggle-margin: 5px auto;
  --tm-toggle-shadow: 0 2px 15px rgba(0, 0, 0, .15);
  --tm-toggle-width: 70%;

  --tm-warning-font-family: inherit;
  --tm-warning-font-weight: 300;
  --tm-warning-font-size: var(--font-size-md);
  --tm-warning-padding: var(--padding-sm) 0;
  --tm-warning-margin: var(--padding-sm) 0;

  --icon-done-color: var(--tm-primary-color);
  --icon-done-font-size: 18px;
  --icon-done-margin: 0;
  --icon-done-width: 25px;

  --icon-edit-color: var(--tm-primary-color);
  --icon-edit-font-size: 20px;
  --icon-edit-margin: 0;
  --icon-edit-width: 25px;

}

// Static image assets are uploaded here: https://s3.console.aws.amazon.com/s3/buckets/canadianblackbook.com?prefix=images/&region=us-east-1
html, body, app-body {
  position: relative;
  height: 100%;
}

app-body {
  >div {
    height: 100%;
  }
}

html {
  -webkit-text-size-adjust: none; /* Prevent font scaling in landscape */
}

body{
  min-width: map-get($breakpoints, small-mobile);
  margin-left: auto;
  margin-right: auto;
  font-size: var(--font-size-md);
}

.background {
  background: var(--background);
  margin: 0px;
  padding: 1px;
}

.radio-button{
  @include input.radio;
}
