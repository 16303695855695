@use './common' as func;
@use './breakpoints' as breakpoint;
@import './varibles';

@font-face {
  font-family: neuehassgrotesk;
  src: url(https://fonts.blackbookinformation.com/f53e5775-ed10-4b0d-bae1-efc8fb73f320.woff2);
}

@font-face {
  font-family: neuehassgrotesklight;
  src: url(https://fonts.blackbookinformation.com/70eef63f-fba8-4b8a-8ab6-01e0fe4d61b2.woff2);
}

@mixin HelveticaBold($type){
  font-family: var(--font-family-helvetica);
  font-weight: Bold;
  font-size: func.map-deep-get($font-size, desktop, $type);
  @include breakpoint.mobile{
    font-size: func.map-deep-get($font-size, mobile, $type);
  }
}

@mixin HelveticaRegular($type){
  font-family: var(--font-family-helvetica);
  font-weight: normal;
  font-size: func.map-deep-get($font-size, desktop, $type);
  @include breakpoint.mobile{
    font-size: func.map-deep-get($font-size, mobile, $type);
  }
}

@mixin HelveticaLight($type){
  font-family: var(--font-family-helvetica);
  font-weight: 300;
  font-size: func.map-deep-get($font-size, desktop, $type);
  @include breakpoint.mobile{
    font-size: func.map-deep-get($font-size, mobile, $type);
  } 
}

@mixin NeueBold($type){
  font-family: var(--font-family-helvetica);
  font-size: func.map-deep-get($font-size, "desktop", $type);
  @include breakpoint.mobile{
    font-size: func.map-deep-get($font-size, "mobile", $type);
  }    
}  

@mixin NeueLight($type){
  font-family: var(--font-family-helvetica-light);
  font-size: func.map-deep-get($font-size, "desktop", $type);
  font-weight: 300;
  @include breakpoint.mobile{
    font-size: func.map-deep-get($font-size, "mobile", $type);
    font-weight: 300;
  }    
} 